import React, { Component } from 'react'; 
import './css/flightState.css';
import * as contentful from '../../../lib/contentful';
import getLocale from '../../../utils/getLocale';
import i18next from 'i18next';
import { isLocal, isFromDev, isFromQA } from './../../../core/Ambientes';



const urlLocal = `${process.env.REACT_APP_LOCAL}`;
const urlDev = `${process.env.REACT_APP_CADMIN_DEV}`;
const urlQA = `${process.env.REACT_APP_CADMIN_QA}`
const urlProd = `${process.env.REACT_APP_CADMIN_PROD}`

const tokenLocal = `${process.env.REACT_APP_TOKEN_LOCAL}`;
const tokenDev   = `${process.env.REACT_APP_CADMIN_DEV_TOKEN}`;
const tokenQA 	 = `${process.env.REACT_APP_CADMIN_QA_TOKEN}`;
const tokenProd  = `${process.env.REACT_APP_API_TOKEN}`

// # Liga validada
const urlValid = isLocal === true ? urlLocal 
	: isFromDev === true ? urlDev
	: isFromQA  === true? urlQA
	: urlProd

// Se genera el token segun el ambiente
const tokenValid = isLocal === true ? tokenLocal
	: isFromDev === true ? tokenDev
	: isFromQA === true ? tokenQA
	: tokenProd

	const cabecera = new Headers({
		"Content-type": "application/json",
		Authorization: "Bearer " + tokenValid
	});


export default class flightPlaneState extends Component {
	constructor() {
		super();
		this.toggle = this.toggle.bind(this);
		this.state = {
			loading: true,
			error: null,
			entries: null,
			language: i18next.language,
			tooltipOpen: false,
			planVuelo: null,
			loadingPlan: true
		};
	}
	componentDidMount() {
	
		this.fetchEntries();
		fetch(this.state.language === 'es'
				? `${urlValid}traduccion-es`
				: `${urlValid}traduccion-en`, {headers: cabecera})
			.then((res) => res.json())
			.then((res) => { 
				console.log("Traducciones EN: ", res.data.attributes.data.Inicio.planVuelo.plan2021);
				this.setState({
					...this.state,
					planVuelo: res.data.attributes.data.Inicio.planVuelo.plan2021,
					loadingPlan: false
				});
			})
			.catch((err) => console.error(err));
	}

	toggle() {
		this.setState({
			tooltipOpen: !this.state.tooltipOpen
		});
	}

	planVueloLink() {
		if (i18next.language === 'en') {
			return '/nosotros/plan-de-vuelo/ingles';
		} else {
			return '/nosotros/plan-de-vuelo';
		}
	}

	fetchEntries = () => {
		contentful.client
			.getEntries({
				content_type: 'stateOfAirline',
				include: 2,
				locale: getLocale()
			})
			.then((response) => response.items)
			.then((data) => {
				
				this.setState({
					loading: false,
					entries: data
				});
			})
			.catch((error) => {
				this.setState({
					loading: false,
					error: error
				});
			})
			.catch((err) => console.error(err));
	};

	render() {
		// const { loading, entries, error } = this.state;
		return (
			<div className="stateFlightPlane overflow-hidden">
				<div class="TitleState flightplanstate bg-[#020C41]">
					<p className="w-full pl-0 text-center">{i18next.t('Inicio.planVuelo.title')}</p>
					{/* <p>{i18next.t('Inicio.planVuelo.sub')}</p> */}
				</div>
				<div className="contDataFlight bg-[#030D75]">
					{/* AQUI VA EL MAP */}
					{!this.state.loadingPlan && (
						<>
							{this.state.planVuelo.map((item) => (
								<div
									key={item.id}
									className="DataWrapper bg-[#030D75]"
									id={item.id + '-Tooltip'}
								>
									{/* <UncontrolledTooltip
										placement="auto"
										autohide={false}
										target={item.id + '-Tooltip'}
									>
										{item.description}
									</UncontrolledTooltip> */}
									<div className="textWrapper">
										<div className="CellWrapper">
											<p>{item.title}</p>
										</div>
									</div>
									<div className="graphWrapper">
										<div className="graphbgColor">
											<div className="goingGraphColor"></div>
										</div>
									</div>
								</div>
								// <div
								// 	key={item.title}
								// 	className="DataWrapper"
								// 	id={item.title + 'Tooltip'}
								// >
								// 	<UncontrolledTooltip
								// 		placement="auto"
								// 		autohide={false}
								// 		target={item.title + 'Tooltip'}
								// 	>
								// 		{item.description}
								// 	</UncontrolledTooltip>
								// 	<div className="textWrapper">
								// 		<div className="CellWrapper">
								// 			<p>{item.title}</p>
								// 		</div>
								// 	</div>
								// 	<div className="graphWrapper">
								// 		<div className="graphbgColor">
								// 			<div className="goingGraphColor"></div>
								// 		</div>
								// 	</div>
								// </div>
							))}
						</>
					)}
					{/* <div className="DataWrapper" id="SecurityToolTip"> */}
					{/* <UncontrolledTooltip
							placement="auto"
							autohide={false}
							target="SecurityToolTip"
							delay={{ show: 0, hide: 0 }}
						>
							{i18next.t('Inicio.planVuelo.seguridadTip')}
						</UncontrolledTooltip> */}
					{/* YA ESTABA COMENTADO */}
					{/*
                <div class="tooltipCompensationsNPS tooltipDO"><div><span></span><p class="toolTipNPS tooltipnps">
                Poner la seguridad primero, siempre
                </p></div></div>*/}
					{/* YA ESTABA COMENTADO END */}

					{/* <div className="textWrapper">
							<div className="CellWrapper">
								<p>{i18next.t('Inicio.planVuelo.seguridad')}</p>
							</div>
						</div>
						<div className="graphWrapper">
							<div className="graphbgColor">
								<div className="goingGraphColor"></div>
							</div>
						</div>
					</div> */}

					{/* <div className="DataWrapper" id="EmployeeToolTip">

						<UncontrolledTooltip
							placement="auto"
							autohide={false}
							target="EmployeeToolTip"
							delay={{ show: 0, hide: 0 }}
						>
							{i18next.t('Inicio.planVuelo.exEmpTip')}
						</UncontrolledTooltip>

						<div className="textWrapper">
							<div className="CellWrapper">
								<p>{i18next.t('Inicio.planVuelo.exEmp')}</p>
							</div>
						</div>
						<div className="graphWrapper">
							<div className="graphbgColor">
								<div className="goingGraphColor"></div>
							</div>
						</div>
					</div> */}

					{/* <div className="DataWrapper" id="CustomerToolTip">

						<UncontrolledTooltip
							placement="auto"
							autohide={false}
							target="CustomerToolTip"
							delay={{ show: 0, hide: 0 }}
						>
							{i18next.t('Inicio.planVuelo.exClienteTip')}
						</UncontrolledTooltip>

						<div className="textWrapper">
							<div className="CellWrapper">
								<p>{i18next.t('Inicio.planVuelo.exCliente')}</p>
							</div>
						</div>
						<div className="graphWrapper">
							<div className="graphbgColor">
								<div className="goingGraphColor"></div>
							</div>
						</div>
					</div> */}

					{/* <div className="DataWrapper" id="EficienciaToolTip">

						<UncontrolledTooltip
							placement="auto"
							autohide={false}
							target="EficienciaToolTip"
							delay={{ show: 0, hide: 0 }}
						>
							{i18next.t('Inicio.planVuelo.eficienciaTip')}
						</UncontrolledTooltip>

						<div className="textWrapper">
							<div className="CellWrapper">
								<p>
									{i18next.t('Inicio.planVuelo.eficiencia')}
								</p>
							</div>
						</div>
						<div className="graphWrapper">
							<div className="graphbgColor">
								<div className="goingGraphColor"></div>
							</div>
						</div>
					</div> */}

					{/* <div className="DataWrapper" id="EjecucionToolTip">

						<UncontrolledTooltip
							placement="auto"
							autohide={false}
							target="EjecucionToolTip"
							delay={{ show: 0, hide: 0 }}
						>
							{i18next.t('Inicio.planVuelo.ejecucionTip')}
						</UncontrolledTooltip>

						<div className="textWrapper">
							<div className="CellWrapper">
								<p>{i18next.t('Inicio.planVuelo.ejecucion')}</p>
							</div>
						</div>
						<div className="graphWrapper">
							<div className="graphbgColor">
								<div className="goingGraphColor"></div>
							</div>
						</div>
					</div> */}
					{/* <div className="DataWrapper" id="EleccionToolTip">
						<UncontrolledTooltip
							placement="auto"
							autohide={false}
							target="EleccionToolTip"
							delay={{ show: 0, hide: 0 }}
						>
							{i18next.t('Inicio.planVuelo.eleccionTip')}
						</UncontrolledTooltip>
						<div className="textWrapper">
							<div className="CellWrapper">
								<p>{i18next.t('Inicio.planVuelo.eleccion')}</p>
							</div>
						</div>
						<div className="graphWrapper">
							<div className="graphbgColor">
								<div className="goingGraphColor"></div>
							</div>
						</div>
					</div> */}
				</div>

				<div className="significados bg-[#030D75] text-white">
					<div className="cont text-white opacity-100">
						{/*<div className="cir"></div>*/}
						<p className="py-2 text-white opacity-100">{i18next.t('Inicio.planVuelo.actualizado')}</p>
					</div>

					{/*<div className="cont">
                    <div className="cir"></div>
                    <p>Medio</p>
                </div>

                <div className="cont">
                    <div className="cir"></div>
                    <p>Malo</p>
                </div>
    */}
				</div>

				{/* <Link to={this.planVueloLink()}>
					{i18next.t('Inicio.planVuelo.ver')}
				</Link> */}
				<a
					href={i18next.t('Inicio.planVuelo.planURL')}
					className='bg-[#020C41]'
					target="_blank"
					rel="noopener noreferrer">
					{i18next.t('Inicio.planVuelo.ver')}
				</a>
			</div>
		);
	}
}
